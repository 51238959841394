import React from 'react'
import {
  experience,
  userEmail,
  userJobTitle,
  userLocation,
  userLocationUrl,
  userName,
  userPhone
} from '../../site-config'
import { ContactEntry } from '../components/ContactEntry'
import { HeaderSpacer } from '../components/HeaderSpacer'
import { Headline } from '../components/Headline'
import { Page } from '../components/layout/Page'
import { ProjectWithGallery } from '../components/ProjectWithGallery'
import { Section } from '../components/Section'
import abb0 from '../static/images/projects/abb/0.png'
import abb1 from '../static/images/projects/abb/1.png'
import cyc1 from '../static/images/projects/cyc/1.png'
import cyc2 from '../static/images/projects/cyc/2.png'
import o1 from '../static/images/projects/ocean/1.jpg'
import o2 from '../static/images/projects/ocean/2.png'
import o3 from '../static/images/projects/ocean/3.jpg'
import o4 from '../static/images/projects/ocean/4.jpg'
import ta0 from '../static/images/projects/ta/0.png'
import ta1 from '../static/images/projects/ta/1.png'
import ta2 from '../static/images/projects/ta/2.png'
import ta3 from '../static/images/projects/ta/3.png'
import wag1 from '../static/images/projects/wag/1.png'
import wag2 from '../static/images/projects/wag/2.png'
import wag3 from '../static/images/projects/wag/3.png'

const p = (src, width = 3, height = 2) => ({
  src: src.toString(), width, height
})

const freelance = 0
const ta = 0
const rf = 1
const abb = 3
const ocean = 0
const wag = 1
const cyc = 2

const taProject = experience[freelance].projects[ta]
taProject.photos = [
  p(ta0, 112, 96), p(ta1, 2, 1), p(ta2, 2, 1), p(ta3, 14, 11)]

const oceanProject = experience[rf].projects[ocean]
oceanProject.photos = [
  p(o1), p(o2), p(o4), p(o3)
]

const cycProject = experience[rf].projects[cyc]
cycProject.photos = [
  p(cyc1, 2, 1), p(cyc2, 2, 1)
]

const wagProject = experience[rf].projects[wag]
wagProject.photos = [
  p(wag1, 1, 2), p(wag2, 1, 2), p(wag3, 1.2, 1)
]
const abbProject = {
  name: 'ABB CMS and applications',
  description: experience[abb].overview,
  photos: [p(abb0, 115, 96), p(abb1, 2, 3)],
  website: 'https://abb.com'
}

export default (props) => {
  return <Page {...props}>
    <HeaderSpacer>
      <Section>
        <HeaderSpacer>
          <h1 className="header-user-name">{userName}</h1>
          <h4 className="header-job-title">{userJobTitle}</h4>
          <ul className="list-inline">
            <li className="list-inline-item">
              <ContactEntry url={'tel:' + userPhone} iconClassName='fas fa-phone' text={userPhone} />
            </li>
            <li className="list-inline-item contact-entry">
              <ContactEntry url={'mailto:' + userEmail} iconClassName='fas fa-at' text={userEmail} />
            </li>
            <li className="list-inline-item contact-entry">
              <ContactEntry url={userLocationUrl} iconClassName='fas fa-home' text={userLocation} />
            </li>
          </ul>
        </HeaderSpacer>
      </Section>
      <Section>
        <Headline>Selected Projects</Headline>
        <div className='spacer'>
          <ProjectWithGallery {...taProject} />
          <ProjectWithGallery {...oceanProject} />
          <ProjectWithGallery {...cycProject} />
          <ProjectWithGallery {...wagProject} />
          <ProjectWithGallery {...abbProject} />
        </div>
      </Section>
    </HeaderSpacer>
  </Page>
}
