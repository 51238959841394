import React from 'react'
import { DynamicGallery } from './DynamicGallery'
import { PrintGallery } from './PrintGallery'
import styles from './ProjectWithGallery.module.css'

export const ProjectWithGallery = ({ photos, name, website, extendedDescription, description }) => {
  const printMode = false

  return <div className={styles.wrapper}>
    <h6 className={styles.name}>{name}</h6>
    <a target='_blank' className={styles.website} href={website}>{website}</a>
    <p className={styles.description}>{extendedDescription || description}</p>
    <div className={styles.gallery}>
      {printMode ? <PrintGallery photos={photos} /> : <DynamicGallery photos={photos} />}
    </div>
  </div>
}
